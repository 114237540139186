import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { Button, Modal, Input, message } from 'antd';
import download from 'downloadjs';
import { checkCode, downloadFile } from '@/api/config'
import utils from '@/utils'

const Download = (prop, ref) => {
    useImperativeHandle(ref, () => ({
        setOpen,
        setType
    }))
    const [progress, setProgress] = useState(0);
    const [open, setOpen] = useState(false)
    const [type, setType] = useState(1)
    const handleCancel = () => {
        setOpen(false)
    }
    useEffect(() => {
       if (open) {
           setType(1)
           setCode('')
       }
    }, [open])
    const checkCodeSure = async () => {
        if (!code) {
            message.error('请输入取件码')
        }
        let parmas = {
            code: code
        }
        if(type == 2) {
            parmas['password'] = password
        }
        const res = await checkCode(parmas)
        if (res.code == 40001) {
            setType(2)
        } else if (res.code == 0) {
            handleDownload(res.data.id, res.data.size, res.data.name)
        } else  {
            message.error(res.message)
        }
    }
    const handleDownload = async (fileId, fileSize, fileName) => {
        const chunkSize = 1024 * 1024; // 1MB
        let startByte = 0;
        let endByte = Math.ceil(fileSize / chunkSize);
        let chunks = [];

        const downloadChunk = async () => {
            const res = await downloadFile(
                { id: fileId },
                { start: startByte * chunkSize, 'Content-Type': 'multipart/form-data' }
            );
            startByte += 1;
            chunks.push(res);
            const progress = ((startByte / endByte) * 100).toFixed(2);
            prop.onLoad(true, `文件下载中 ${progress}%`);
            if (startByte < endByte) {
                downloadChunk();
            } else {
                prop.onLoad(true, '文件下载中 100%');
                const fileData = utils.mergeArrayBuffer(chunks);
                message.success('文件下载成功！');
                prop.onLoad(false);
                download(fileData, fileName, 'application/octet-stream');
                setType(1);
                setCode('');
                setPassword('');
                setOpen(false);
            }
        };

        prop.onLoad(true, '文件下载中...');
        await downloadChunk();
    };
    const [code, setCode] = useState('')
    const changeInput = (e) => {
        setCode(e.target.value)
    }

    const [password, setPassword] = useState('')
    const changeInput2 = (e) => {
        setPassword(e.target.value)
    }
    return (
        <Modal title="" open={open} footer={[]} onCancel={handleCancel}>
            <div style={{ paddingTop: 30, textAlign: 'center' }}>
                {
                    type == 1 ? <>    <h2 style={{ marginBottom: 20 }}>请输入取件码</h2>
                        <p>发送文件后就会获得取件码</p>
                        <Input value={code} onInput={changeInput} style={{ width: 200, margin: '20px 0 ' }} />
                        <br />
                        <Button type='primary' onClick={checkCodeSure} style={{ marginTop: 10 }}>确定</Button></> : <></>
                }
                {
                    type == 2 ? <>
                        <h4>
                            该文件加密,需要输入文件密码.
                        </h4>
                        <Input value={password} onInput={changeInput2} style={{ width: 200, margin: '20px 0 ' }} />
                        <br />
                        <Button type='primary' onClick={checkCodeSure} style={{ marginTop: 10 }}>确定</Button>
                    </> : <></>
                }
            </div>
        </Modal>
    );
};

export default forwardRef(Download)
