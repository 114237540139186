import { $axios, $bufferAxios } from "@/utils/request";

//获取路由权限
export const upload = (data) => {
    return $axios({
        url: 'file/upload',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    })
}

export const uploadChunk = (data) => {
    return $axios({
        url: 'file/uploadChunk',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    })
}

export const checkCode = (data) => {
    return $axios({
        url: 'file/checkCode',
        method: 'get',
        params: data
       
    })
}

export const downloadFile = (data, headers) => {
    return $bufferAxios({
        url: 'file/download',
        method: 'post',
        data,
        headers
    })
}