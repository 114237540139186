import moment from "moment"

/**
 * 时间格式化
 * 将 2018-09-23T11:54:16.000+0000 格式化成 2018/09/23 11:54:16
 * @param datetime 国际化日期格式
 */
function format(datetime, value = "YYYY-MM-DD HH:mm:ss", isPase = false) {
    if (!datetime) return ''
    if (isPase) return moment(datetime).valueOf()
    return moment(datetime).format(value)
}


/**
 * 过滤对象
 * 拼接url查询条件
 * @param params 条件对象
 */
function parFilter(params) {
    let obj = { ...params }
    for (var o in obj) {
        if (obj[o] === "" || obj[o] === undefined || obj[o] === null || obj[o] === 'null' || obj[o].length === 0) {
            delete obj[o];
        }
    }
    return obj;
}

/**
 * 下载方法
 * @param api 接口地址
 * @param option 接口所需参数
 * @param name 下载文件名
 */
function Download(api, option = {}, name = '导出') {
    return new Promise(async (resolve, reject) => {
        try {
            console.log(data)
            const data = await api(option)
            if (!data) {
                return reject(false)
            }
            let url = await window.URL.createObjectURL(new Blob([data]))
            let link = document.createElement("a")
            link.style.display = "none"
            link.href = url
            link.setAttribute("download", `${name}.xlsx`)
            document.body.appendChild(link)
            link.click()
            resolve(true)
        } catch (e) {
            reject(e)
        }
    })
}

/**
 * 物流状态
 */
function wuliuStatus(isPase = false) {
    var wuList = [
        { value: 1, label: '已签收' },
        { value: 2, label: '拒签' },
        { value: 3, label: '异常' },
        { value: 4, label: '派送中' },
        { value: 5, label: '运送中' },
        { value: 6, label: '退回' },
        { value: 7, label: '作废' },
        { value: 0, label: '其他' }
    ]
    if (isPase) {
        let obj = {}
        for (let i in wuList) {
            let o = wuList[i]
            obj[o['value']] = o['label']
        }
        return obj
    }
    return wuList
}

function Logisticscompany() {
    return [
        { value: 30, label: '京东快递' },
        { value: 12, label: '顺丰快递' },
        { value: 8, label: '申通快递' },
        { value: 13, label: '德邦快递' },
        { value: 15, label: '中通快递' },
        { value: 7, label: '圆通快递' },
        { value: 9, label: '韵达快递' },
        { value: 19, label: '邮政快递' },
        { value: 20, label: '百世汇通' }
    ]
}

/**
 * 话务包处理
 * @param arr 需要的字段数组
 */
function huaWuType(arr = [], isPase = false) {
    let list = [
        { value: 0, label: '未处理' },
        { value: 1, label: '优惠' },
        { value: 2, label: '再次派送' },
        { value: 3, label: '拒签' },
        { value: 4, label: '未接通' },
        { value: 5, label: '第二次未接通' },
        { value: 6, label: '第三次未接通' },
        { value: 7, label: '客户自取' },
        { value: 8, label: '转寄' },
        // { value: 9, label: '客服已处理' },
        { value: 10, label: '已签收' },
        { value: 11, label: '提高成功' },
        { value: 12, label: '核单成功' },
        { value: 13, label: '核单失败' },
        { value: 14, label: '复购成功' },
        { value: 15, label: '复购失败' },
        { value: 16, label: '复购意向' },
        { value: 17, label: '超区' },
        // { value: 20, label: '复购加购' },
        { value: 22, label: '改评成功' },
        { value: 23, label: '改评失败' },
        { value: 24, label: '改评意向' },
        { value: 25, label: '确认收货,给好评' },
        { value: 26, label: '确认收货,不给好评' },
        { value: 27, label: '不确认收货,给好评' },
        { value: 28, label: '不确认收货,不给好评' },
        // { value: 21, label: '加购单' },
        { value: 29, label: '售后成功' },
        { value: 30, label: '售后失败' },
        { value: 91, label: '优惠已联系快递' },
        { value: 92, label: '再派已联系快递' },
        { value: 98, label: '转寄已联系快递' }
    ]
    if (arr.length > 0) {
        list = list.filter(l => {
            return arr.includes(l.value)
        })
    }
    if (isPase) {
        let obj = {}
        for (let i in list) {
            let o = list[i]
            obj[o['value']] = o['label']
        }
        return obj
    }
    return list
}

/**
 * 跟进状态
 */
function handleType() {
    let arr = [
        { id: 1, value: "打不通" },
        { id: 2, value: "拒收" },
        { id: 3, value: "改价" },
        { id: 4, value: "再次派送" },
        { id: 5, value: "其他" }
    ]
    return arr
}

/**
 * 格式化话务跟踪处理内容
 */
function HandleNote(row) {
    let { type, note, peisong_date, peisong_time, update_price, template_param } = row
    if (type === 2) {
        return `已将云客服提供需求处理，并且安排再次配送，时间为：${peisong_date}${peisong_time}。反馈为：${note}`
    } else if (type === 1) {
        let { create_time } = row
        return `已将云客服提供需求改价为（￥${update_price}）时间为（${format(create_time)}）并且安排再次配送，时间为：${peisong_date}${peisong_time}。反馈为：${note}`
    } else if (type === 8) {
        return `已将云客服提供需求处理，并且安排转寄，转寄地址为：${note}`
    } else if (type === 11) {
        let { update_specs } = row
        return `已将云客服提供需求提高客单价，规格为：${update_specs}，整改价格为${update_price}`
    } else {
        return template_param
    }
}

/**
 * 话务包类型
 */
function handlePackType(arr = [], isPase = false) {
    let list = [
        { value: 1, label: '核单' },
        { value: 2, label: '异常' },
        { value: 3, label: '拒收' },
        { value: 4, label: '复购' },
        { value: 5, label: '差评' },
        { value: 6, label: '好评' },
        { value: 7, label: '售后' },
    ]
    if (arr.length > 0) {
        list = list.filter(l => {
            return arr.includes(l.value)
        })
    }
    if (isPase) {
        let obj = {}
        for (let i in list) {
            let o = list[i]
            obj[o['value']] = o['label']
        }
        return obj
    }
    return list
}

/**
 * 判断浏览器
 */
function getBrowser() {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('OPR') > -1) { //欧朋浏览器
        return 'Opera'
    }
    if (userAgent.indexOf('Firefox') > -1) { //火狐
        return 'FF'
    }
    if (userAgent.indexOf('Trident') > -1) { //IE
        return 'IE'
    }
    if (userAgent.indexOf('Edge') > -1) { //微软浏览器
        return 'Edge'
    }
    if (userAgent.indexOf('Chrome') > -1) { //谷歌
        return 'Chrome'
    }
    if (userAgent.indexOf('Safari') > -1) { //苹果浏览器
        return 'Safari'
    }
}

/**
 * 订单状态
 */
function getOrderStatus(isPase = false) {
    const list = [
        { value: 0, label: '全部' },
        { value: 50, label: '待发货' },
        // { value: 1, label: '待确认' },
        // { value: 6, label: '待支付' },
        // { value: 2, label: '备货中' },
        { value: 3, label: '已发货' },
        { value: 5, label: '已完成' },
        { value: 4, label: '已关闭' },

    ]
    if (isPase) {
        let obj = {}
        for (let i in list) {
            let o = list[i]
            obj[o['value']] = o['label']
        }
        return obj
    }
    return list
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile(s) {
    return /^1[0-9]{10}$/.test(s)
}

/**
 * 电话号码
 * @param {*} s
 */
export function isPhone(s) {
    return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(s)
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL(s) {
    return /^http[s]?:\/\/.*/.test(s)
}


function alignBuffer(buffer) {
    const padding = 4 - buffer.byteLength % 4;
    if (padding !== 4) {
        const alignedBuffer = new Uint8Array(buffer.byteLength + padding);
        alignedBuffer.set(new Uint8Array(buffer), 0);
        return alignedBuffer.buffer;
    }
    return buffer;
}

function mergeArrayBuffer(buffers) {
    const alignedBuffers = buffers.map(buffer => alignBuffer(buffer));
    const totalLength = alignedBuffers.reduce((acc, buffer) => acc + buffer.byteLength, 0);
    const result = new Uint8Array(totalLength);
    let offset = 0;
    alignedBuffers.forEach(buffer => {
        result.set(new Uint8Array(buffer), offset);
        offset += buffer.byteLength;
    });
    return result.buffer;
}
export default {
    format,
    parFilter,
    Download,
    wuliuStatus,
    Logisticscompany,
    huaWuType,
    handleType,
    HandleNote,
    handlePackType,
    getBrowser,
    getOrderStatus,
    mergeArrayBuffer
}
