import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from './route'
class App extends React.Component {
    render() {
        //const authPath = '/login' // 默认未登录的时候返回的页面，可以自行设置
        //let authed = this.props.state.authed || false // 如果登陆之后可以利用redux修改该值
        return (
            <Router >
                <Routes>
                    {routes.map((route, i) => {
                        console.log(route.path)
                        return (
                            <Route
                                key={i}
                                path={route.path}
                                exact={route.exact ? true : false}
                                render={(props) => {
                                    if (props.location.pathname === '/') { //首页重定向登录页面
                                        // return <Navigate to={{ pathname: '/login' }} />
                                    }
                                }} 
                                element={<route.component />}
                                />
                        )
                    })}
                </Routes>
            </Router>
        );
    }
}

export default App;
