import React, { useState, useRef, useEffect, createRef } from 'react';
import { Input, Button, List, Avatar, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import UploadIng from './component/upload';
import Download from './component/download';
function ChatRoom() {
    const chatRoomRef = React.createRef();
    const DownloadRef = React.createRef();
    const openUpload = () => {
        chatRoomRef.current.setOpen(true)
        chatRoomRef.current.setType(2)
    }

    const openDownload = () => {
        DownloadRef.current.setOpen(true)
        DownloadRef.current.setType(1)
    }
    const [loading, setLoading] = useState(false)
    const [loadingText, setLoadingText] = useState('')
    const changeLoad = (value, text) => {
        setLoadingText(text)
        setLoading(value)
    }
    return (
        <Spin style={{ zIndex: 99999, height: '100%', maxHeight: '100%', width: '100%', position: 'fixed', background: 'rgba(0, 0, 0, 0.4)' }} spinning={loading} tip={loadingText} size="large">
            <div style={{
                minWidth: 350,
                maxWidth: 800,
                margin: 'auto',
                paddingBottom: 30,
                textAlign: 'center'
            }}>
                <div className='login'>文件快递</div>
                <div className='btn'>
                    <a className='send' onClick={openUpload}>发送</a>
                    <a className='get' onClick={openDownload}>接收</a>
                </div>
                <UploadIng ref={chatRoomRef} onLoad={changeLoad}></UploadIng>
                <Download ref={DownloadRef} onLoad={changeLoad}></Download>
            </div>
        </Spin>
    );
}

export default ChatRoom;
