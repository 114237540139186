import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Button, Modal, Row, Col, Input, Form, InputNumber, message, Upload, QRCode } from 'antd';
import { MailOutlined, FileAddOutlined, UploadOutlined } from '@ant-design/icons';
import { upload, uploadChunk } from '@/api/config'
import utils from '@/utils'
import MD5 from 'md5'
import SparkMD5 from 'spark-md5'
function UploadIng(prop, ref) {
    const [form] = Form.useForm();
    useImperativeHandle(ref, () => ({
        setOpen,
        setType
    }))
    const handleCancel = () => {
        setOpen(false)
    }

    const [open, setOpen] = useState(false)
    const [type, setType] = useState(3)



    const next = (pramsType) => {
        setType(2)
    }

    const onFinishFailed = (error) => {
        console.log(error)
    }
    const [query, setQuery] = useState({
        downloadTimes: 2,
        time: 24,
        password: ''
    })
    useEffect(() => {
        if (open) {
            form.resetFields();
        }
        setQuery({
            downloadTimes: 2,
            time: 24,
            password: ''
        })
    }, [open])
    const onFinish = (values) => {
        setQuery(values)
    }
    const myRef = React.createRef();
    const [fileUid, setFileUid] = useState(null);
    const CHUNK_SIZE = 1024 * 1024; // 1MB

    function getUploadedChunks(uid) {
        const chunks = localStorage.getItem(uid);
        return chunks ? JSON.parse(chunks) : [];
    }

    function setUploadedChunks(uid, chunks) {
        localStorage.setItem(uid, JSON.stringify(chunks));
    }
    const handleBeforeUpload = (file) => {
        return true;
    };
    const handleCustomRequest = async ({ file, onProgress, onSuccess, onError }) => {
        const fileSize = file.size
        const chunkCount = Math.ceil(fileSize / CHUNK_SIZE)
        prop.onLoad(true, '文件上传中...')
        let uid = MD5(file.name+'_'+ file.size)
        if (chunkCount <= 1) {
            burstFile(file, uid, 0, 1, fileSize, chunkCount)
        } else {
            // burstFile(file, uid, -1, 3, fileSize, chunkCount)
            burstFile(file, uid, -1, chunkCount, fileSize, chunkCount)
        }

    };

    const burstFile = async (file, uid, start, end, fileSize, chunkCount) => {
        if(start == -1 && start == 0) {
            prop.onLoad(true, '文件上传中...')
        }
        let chunkStart = start * CHUNK_SIZE
        let chunkEnd = Math.min(fileSize, chunkStart + CHUNK_SIZE)
        let _chunkFile = file.slice(chunkStart, chunkEnd)
        const formData = new FormData();
        if (start != -1) {
            formData.append('block', _chunkFile);
        }
        // console.log(chunkStart, chunkEnd, _chunkFile, fileSize, start)
        formData.append('fileName', file.name)
        formData.append('uuid', uid)
        formData.append('start', start == -1 ? -1 : chunkStart)
        // formData.append('totalChunks', chunkCount)
        formData.append('total', fileSize)
        formData.append('downloadTimes', query.downloadTimes || '');
        formData.append('password', query.password || '');
        formData.append('time', query.time || '');
        const res = await uploadChunk(formData)
        if (res.code == 0) { //2f1e861d563b5782f9dcd23ac8b98a38   //2f1e861d563b5782f9dcd23ac8b98a38
            if (start == -1) {
                burstFile(file, uid, Number(parseInt(res.data.start / CHUNK_SIZE)), end, fileSize, chunkCount)
                return
            }
            if (start >= end) {
                setCode(res.data.code)
                setType(3)
                prop.onLoad(false, '')
                message.success('文件上传成功！')
            } else {
                let startTwo = start + 1
                const progress = ((startTwo / end) * 100).toFixed(2);
                console.log(progress)
                prop.onLoad(true, `文件上传中 ${progress}%`);
                burstFile(file, uid, startTwo, end, fileSize, chunkCount)
            }
        } else {
            prop.onLoad(false, '')
            message.error('文件上传失败！')
        }

    }

    // 获取文件的MD5
    const getFileMD5 = (file) => {
        const fileSize = file.size
        const chunkCount = Math.ceil(fileSize / CHUNK_SIZE)
        const spark = new SparkMD5.ArrayBuffer()
        for (let i = 0; i < chunkCount; i++) {
            let start = i * CHUNK_SIZE;
            let end = ((start + CHUNK_SIZE) >= fileSize) ? fileSize : start + CHUNK_SIZE;
            // 分片的文件
            let chunk = file.slice(start, end);
            // 添加到spark
            spark.append(chunk);
        }
        // 计算出md5
        const md5 = spark.end();
        return md5
    }

    const [code, setCode] = useState('')
    /**
 * 复制指定文本到剪贴板
 *
 * @param {string} text - 需要复制的文本
 * @return {boolean} 是否成功复制到剪贴板
 */
    function copy() {
        const textarea = document.createElement('textarea');
        textarea.value = code;
        document.body.appendChild(textarea);
        textarea.select();
        const result = document.execCommand('copy');
        document.body.removeChild(textarea);
        message.success(`已复制到剪贴板！`);
    }
    return <Modal forceRender title="" open={open} footer={[]} onCancel={handleCancel}>
        {
            type == 1 ?
                <><div className='title'>选择一个功能类型</div>
                    <Row style={{ textAlign: 'center', paddingTop: 40 }} justify="space-between">
                        <Col span={11}>
                            <MailOutlined style={{ fontSize: 60 }} />
                            <div>文本上传</div>
                            <p>上传一段文本,后续可以通过取件码直接获取文本.</p>
                            <p>可以上传长文本,长度不限.</p>
                            <br />
                            <Button type='primary' onClick={() => next(1)}>上传文本</Button>
                        </Col>
                        <Col span={11}>
                            <FileAddOutlined style={{ fontSize: 60 }} />
                            <div>文件上传</div>
                            <p>可以上传多文件,后续通过取件码获取文件列表.不过建议先压缩后上传.支持多文件.</p>
                            <br />
                            <Button type='primary' onClick={() => next(2)}>上传文件</Button>
                        </Col>
                    </Row></>
                : <></>

        }
        {type == 2 ? <>
            <h4>文件列表</h4>
            <div className='queue'></div>
            <Form
                form={form}
                name="basic"
                initialValues={query}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="密码"
                    name="password"
                >
                    <Input placeholder='默认不加密，设置密码可加密' />
                </Form.Item>

                <Row style={{ textAlign: 'center' }} justify="space-between">
                    <Col span={11}>
                        <Form.Item
                            label="下载次数（最大10次）"
                            name="downloadTimes"
                        >
                            <InputNumber max={10} style={{ width: 50 }} />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="保留时间（最大48小时）"
                            name="time"
                        >
                            <InputNumber max={48} style={{ width: 50 }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
                    <Upload beforeUpload={handleBeforeUpload}
                        showUploadList={false}
                        customRequest={handleCustomRequest} ref={myRef} style={{ display: 'none' }}>
                        <Button type="primary" htmlType="submit">
                            点击上传
                        </Button>
                    </Upload>
                </Form.Item>
            </Form>
        </> : <></>}
        {type == 3 ? <div style={{ paddingTop: 30, textAlign: 'center' }}>
            <h2>文件已成功投送</h2>
            <p>您的取件码</p>
            <h1>{code}</h1>
            <QRCode style={{ margin: '0 auto', marginBottom: 10 }} value={code || '-'} />
            <p>您可以<span onClick={copy} style={{ color: '#4191f5', cursor: 'pointer' }}>复制数字提件码</span>，也可以直接截图保存二维码</p>
            <Button type='primary' onClick={() => {
                handleCancel(); copy()
            }} style={{ marginTop: 10 }}>完成</Button>
        </div> : <></>}
    </Modal>
}
export default forwardRef(UploadIng)