import axios from "axios";
import config from "./config";
import utils from "@/utils";

export function $axios(options) {
    return new Promise((resolve, reject) => {
        const instance = axios.create({
            baseURL: options.baseUrl || config.baseUrl,
            headers: config.headers,
            timeout: options.timeout || config.timeout,
        });

        //request 拦截器
        instance.interceptors.request.use(
            (config) => {
                let { params } = config;
                config.params = utils.parFilter(params);
                return config;
            },

            (error) => {
                console.log("request:", error);
                if (error.code === "ECONNABORTED" && error.message.indexOf("timeout") !== -1) {
                    console.log("timeout请求超时");
                    // return service.request(originalRequest);// 再重复请求一次
                }
                return Promise.reject(error);
            }
        );

        //response 拦截器
        instance.interceptors.response.use(
            (response) => {
                let data;
                // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
                if (response.data === undefined) {
                    data = JSON.parse(response.request.responseText);
                } else {
                    data = response.data;
                }

                return data;
            },

            (err) => {
                console.log(JSON.stringify(err));
                if (err.code === "ECONNABORTED" && err.message.indexOf("timeout") !== -1) {
                    return Promise.resolve({ status: 500, message: "网络异常操作失败,请重试一次!" });
                    // return service.request(originalRequest);// 再重复请求一次
                }
                if (err.message.indexOf("Network") !== -1) {
                    return Promise.resolve({ status: 500, message: "网络异常或系统异常" });
                }
                if (err.response && err.response.status === 404) {
                    return Promise.resolve({ status: 404, message: "请求的资源无法找到" });
                } else if (err.response && err.response.status === 500) {
                    return Promise.resolve({ status: 500, message: "服务器繁忙" });
                }
                // console.log(err.response.status)
                return Promise.resolve(err);
            }
        );

        //请求处理
        instance(options)
            .then((res) => {
                resolve(res);
                return false;
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function $bufferAxios(options) {
    return new Promise((resolve, reject) => {
        const instance = axios.create({
            baseURL: options.baseUrl || config.baseUrl,
            headers: config.headers,
            timeout: options.timeout || config.timeout,
            responseType: 'arraybuffer'
        });

        //request 拦截器
        instance.interceptors.request.use(
            (config) => {
                let { params } = config;
                config.params = utils.parFilter(params);
                return config;
            },

            (error) => {
                console.log("request:", error);
                if (error.code === "ECONNABORTED" && error.message.indexOf("timeout") !== -1) {
                    console.log("timeout请求超时");
                    // return service.request(originalRequest);// 再重复请求一次
                }
                return Promise.reject(error);
            }
        );

        //response 拦截器
        instance.interceptors.response.use(
            (response) => {
                console.log(response)
                return response.data;;
            },

            (err) => {
                if (err.code === "ECONNABORTED" && err.message.indexOf("timeout") !== -1) {
                    return Promise.resolve({ status: 500, message: "网络异常操作失败,请重试一次!" });
                    // return service.request(originalRequest);// 再重复请求一次
                }
                if (err.message.indexOf("Network") !== -1) {
                    return Promise.resolve({ status: 500, message: "网络异常或系统异常" });
                }
                if (err.response && err.response.status === 404) {
                    return Promise.resolve({ status: 404, message: "请求的资源无法找到" });
                } else if (err.response && err.response.status === 500) {
                    return Promise.resolve({ status: 500, message: "服务器繁忙" });
                }
                // console.log(err.response.status)
                return Promise.resolve(err);
            }
        );

        //请求处理
        instance(options)
            .then((res) => {
                resolve(res);
                return false;
            })
            .catch((error) => {
                reject(error);
            });
    });
}


