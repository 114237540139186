// import AsyncComponent from '@/components/asyncComponent'

import Index from '@/views/index/index.jsx'
// const HomePage = AsyncComponent(() => import('@/pages/HomePage'))
// const Home = AsyncComponent(() => import('@/pages/Home'))
// const AccountManagement = AsyncComponent(() => import('@/pages/Sys/Account'))
// const RichTextInH5 = AsyncComponent(() => import('../pages/Wx/RichTextInH5'))

// const UserCenter = AsyncComponent(() => import('@/pages/Wx/UserCenter'))
// const UserWithdraw = AsyncComponent(() => import('@/pages/WxUserCenter/Withdraw'))

//配置默认路由
const routes = [
    // {
    //     path: '/',
    //     exact: true,
    //     name: '首页',
    //     component: null
    // },
    // {
    //     path: '/pc',
    //     name: '后台管理',
    //     component: Home,
    //     routes: [
    //         {
    //             path: '/pc/sys/account',
    //             name: '渠道账户管理',
    //             component: AccountManagement
    //         }
    //     ]
    // },
    {
        path: '/',
        name: '登录',
        component: Index
    },
]


export default routes