const baseUrls = {
    local: "http://47.114.138.135/api/",
    // development: "https://www.xiaohuoniu.cn/api/",
    development: "http://47.114.138.135/api/",
    production: "http://47.114.138.135/api/",
};
const NODE_ENV = process.env.NODE_ENV;

export default {
    method: "get",
    //基础url前缀
    baseUrl: baseUrls[NODE_ENV] + "",
    //请求头信息
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
    },
    //基础请求超时时间
    timeout: 1000 * 3000,
};
